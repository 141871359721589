import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { NavItem } from './components';
import { Link, Typography } from '@mui/material';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false, bgcolor = 'black'}) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="NPS"
        width={{ xs: 280, md: 280 }}
      >
        <Box
          component={'img'}
          src={
            mode === 'light' && !colorInvert
              ? 'https://www.npsypr-backup.knrint.in/images/logo.jpg'
              : 'https://www.npsypr-backup.knrint.in/images/logo.jpg'
          }
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'} >
        <Box>
          <Link
            title={'Home'}
            id={'landing-pages'}
            items={landingPages}
            // colorInvert={colorInvert}
            variant={'h5'}
            underline="none"
            href='/'
          > <Typography color={'Black'}>Home</Typography></Link>
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'About Us'}
            id={'company-pages'}
            items={companyPages}
            colorInvert={colorInvert}
            variant={'h5'}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Admissions'}
            id={'account-pages'}
            items={accountPages}
            colorInvert={colorInvert}
            variant={'h5'}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Learning'}
            id={'secondary-pages'}
            items={secondaryPages}
            colorInvert={colorInvert}
            variant={'h5'}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Our Campus'}
            id={'blog-pages'}
            items={blogPages}
            colorInvert={colorInvert}
            variant={'h5'}
          />
        </Box>
        {/* <Box marginLeft={4}>
          <NavItem
            title={'Circular'}
            id={'portfolio-pages'}
            items={portfolioPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Career'}
            id={'portfolio-pages'}
            items={portfolioPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        <Box marginLeft={4}>
          <NavItem
            title={'Contact Us'}
            id={'portfolio-pages'}
            items={portfolioPages}
            colorInvert={colorInvert}
            variant={'h5'}
          />
        </Box>
        

        {/* <Box marginLeft={4}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            target="blank"
            href="https://online-registrations.npsypr.edu.in/"
            size="large"
          >
            Online Registration
          </Button>
        </Box> */}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;